const TokenKey = 'carport_token'

export default {
  getToken() {
    return localStorage.getItem(TokenKey)
  },

  setToken(token) {
    return localStorage.setItem(TokenKey, token)
  },

  removeToken() {
    return localStorage.removeItem(TokenKey)
  }
}
