import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  }

  // {
  //   path: '/',
  //   component: () => import('@/layout'),
  //   redirect: '/home',
  //   name: '驻友之家',
  //   children: [{
  //     path: '/home',
  //     name: '统计分析',
  //     component: () => import('@/views/home/index'),
  //     meta: { title: '驻友之家', icon: 'dashboard' }
  //   }]
  // },
  //
  // {
  //   path: '/config',
  //   component: () => import('@/layout'),
  //   name: '配置管理',
  //   meta: { title: '配置管理', icon: 'setting' },
  //   redirect: '/car_type',
  //   children: [
  //     {
  //       path: '/menu',
  //       component: () => import('@/views/setting/Menu'),
  //       name: '菜单配置',
  //       meta: { title: '菜单配置', icon: 'table' }
  //     },
  //     {
  //       path: '/car_type',
  //       name: '固件管理',
  //       component: () => import('@/views/setting/Firmware'),
  //       meta: { title: '固件管理', icon: 'table' }
  //     }
  //   ]
  // },

  // {
  //   path: '/permissions',
  //   component: () => import('@/layout'),
  //   redirect: '/manager_user',
  //   name: '权限管理',
  //   meta: { title: '权限管理', icon: 'example' },
  //   children: [
  //     {
  //       path: '/manager_user',
  //       name: '管理员管理',
  //       component: () => import('@/views/permissions/UserManager'),
  //       meta: { title: '管理员管理', icon: 'table' }
  //     },
  //     {
  //       path: '/role',
  //       name: '角色管理',
  //       component: () => import('@/views/permissions/Role'),
  //       meta: { title: '角色管理', icon: 'tree' }
  //     }
  //   ]
  // },
  // {
  //   path: '/user',
  //   component: () => import('@/layout'),
  //   redirect: '/user_list',
  //   name: '用户管理',
  //   meta: { title: '用户管理', icon: 'el-icon-s-help' },
  //   children: [
  //     {
  //       path: '/user_list',
  //       name: '用户列表',
  //       component: () => import('@/views/user/UserList'),
  //       meta: { title: '用户列表', icon: 'table' }
  //     }
  //   ]
  // },

  // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true }

]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
