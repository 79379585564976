import router from './router/manifest'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import auth from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // 设置页面的标题
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = auth.getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // token存在时，禁止跳转到登录页面
      next({ path: '/' })
      NProgress.done()
    } else {
      // 已经有了用户信息
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo')

          // 查找当前路由集合中是否包含此路由，没有此路由，默认跳转除了登录和404等第一个页面
          const menuList = store.getters.menuList

          const pathIsCorrect = checkPath(menuList, to.path)

          if (pathIsCorrect) {
            next({ ...to, replace: true })
          } else {
            const path = menuList[2].path
            next({ path: path, replace: true })
          }
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* 本地localStorage没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 若是即将跳转的页面为登录页面，直接放行
      next()
    } else {
      // 其他页面，重定向到登录页面.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

function checkPath(menuList, path) {
  for (let i = 0; i < menuList.length; i++) {
    const menu = menuList[i]
    if (menu.path === path) {
      return true
    }
    if (menu.children && menu.children.length > 0 && checkPath(menu.children, path)) {
      return true
    }
  }
  return false
}

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
